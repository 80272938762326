exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aac-to-text-tsx": () => import("./../../../src/pages/aac_to_text.tsx" /* webpackChunkName: "component---src-pages-aac-to-text-tsx" */),
  "component---src-pages-flac-to-text-tsx": () => import("./../../../src/pages/flac_to_text.tsx" /* webpackChunkName: "component---src-pages-flac-to-text-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-m-4-a-to-text-tsx": () => import("./../../../src/pages/m4a_to_text.tsx" /* webpackChunkName: "component---src-pages-m-4-a-to-text-tsx" */),
  "component---src-pages-mp-2-to-text-tsx": () => import("./../../../src/pages/mp2_to_text.tsx" /* webpackChunkName: "component---src-pages-mp-2-to-text-tsx" */),
  "component---src-pages-mp-3-to-text-tsx": () => import("./../../../src/pages/mp3_to_text.tsx" /* webpackChunkName: "component---src-pages-mp-3-to-text-tsx" */),
  "component---src-pages-mp-4-to-text-tsx": () => import("./../../../src/pages/mp4_to_text.tsx" /* webpackChunkName: "component---src-pages-mp-4-to-text-tsx" */),
  "component---src-pages-pcm-to-text-tsx": () => import("./../../../src/pages/pcm_to_text.tsx" /* webpackChunkName: "component---src-pages-pcm-to-text-tsx" */),
  "component---src-pages-transcribe-english-tsx": () => import("./../../../src/pages/transcribe_english.tsx" /* webpackChunkName: "component---src-pages-transcribe-english-tsx" */),
  "component---src-pages-transcribe-french-tsx": () => import("./../../../src/pages/transcribe_french.tsx" /* webpackChunkName: "component---src-pages-transcribe-french-tsx" */),
  "component---src-pages-transcribe-german-tsx": () => import("./../../../src/pages/transcribe_german.tsx" /* webpackChunkName: "component---src-pages-transcribe-german-tsx" */),
  "component---src-pages-transcribe-italian-tsx": () => import("./../../../src/pages/transcribe_italian.tsx" /* webpackChunkName: "component---src-pages-transcribe-italian-tsx" */),
  "component---src-pages-transcribe-spanish-tsx": () => import("./../../../src/pages/transcribe_spanish.tsx" /* webpackChunkName: "component---src-pages-transcribe-spanish-tsx" */),
  "component---src-pages-wav-to-text-tsx": () => import("./../../../src/pages/wav_to_text.tsx" /* webpackChunkName: "component---src-pages-wav-to-text-tsx" */)
}

